const cnTranslation = {
  Action: '行动',
  Certificate: '证书',
  'Job Name': '职位名称',
  Status: '状态',
  Received: '已收到',
  'Due Date': '截止日期',
  Completed: '完全的',
  'Project Code': '项目代码',
  '# Sample': '＃ 样本',
  'First Sample': '第一个样品',
  'Last Sample': '最后样品',
  'Sample Type': '样本类型',
  Client: '客户',

  'PO Number': '订单号',
  '# Samples': '# 样品',
  'Start Date': '开始日期',
  'Received On': '收到时间',
  Laboratory: '实验室',
  'Order Number': '订单号',
  Project: '项目',
  'Shipment ID': '货件编号',
  Started: '开始',
  'First Reported': '首次报道',
  'Last Reported': '最后报告',
  'Job Description': '职位描述',
  Analytes: '分析物',
  Units: '单位',
  'Detection Limit': '检出限',
  Method: '方法',
  InProgress: '进行中',
  Scheduled: '已预定',
  'Spec Met': '满足规格',
  'Out of Spec': '超出规格',
  Total: '全部的',
  Maximize: '最大化',
  Minimize: '最小化',
  'Created On': '创建于',
  'Job Status': '工作现状',
  Location: '地点',
  Supplier: '供应商',
  'Sample Name': '样本名称',
  'Job Number': '工作编号',
  Sequence: '顺序',
  'Sample Location': '样品位置',
  Source: '资源',
  Brand: '品牌',
  'Date Received': '接收日期',
  'Sampling Date': '采样日期',
  'Report(s)': '报告',
  'SpecMet/NotMet': '符合/不符合规范',
  Description: '描述',
  Parcel: '包裹',
  Approved: '得到正式认可的',
  'Site Country': '站点国家',
  Job: '工作',
  'Job Progress': '工作进度',
  Deliverables: '可交付成果',
  'Laboratory Report': '化验报告',
  'Data Files': '数据文件',
  Invoices: '发票',
  'Other Documents': '其他文件',
  Site: '地点',
  'Sample Date': '采样日期',
  'Component Age': '组件年龄',
  'Filter Changed': '过滤器已更改',
  'Sample Number': '样品编号',
  'Action Number': '行动号',
  'Action Owner': '操作所有者',
  'Action Type': '动作类型',
  'Action Status': '动作状态',
  'Belongs To': '属于',
  'Record ID': '记录编号',
  Created: '已创建',
  'Started On': '开始于',
  'Started By': '开始于',
  'Completed On': '完成于',
  'Client Name': '客户名称',
  'Client Worksite': '客户工地',
  'Client Address': '客户地址',
  'Lab No': '实验室编号',
  'Received Date': '收到的日期',
  'Completion Date': '完成日期',
  'Tracking No': '追踪号码',
  'Reference No': '参考编号',
  'Work Order No': '工单号',
  'PO No': '订单号',
  'Make Up Oil Amount': '补油量',
  Port: '港口',
  'IMO No': '海事组织没有',
  'Engine Hours': '引擎小时数',
  'Engine Type': '引擎种类',
  'No of Cylinders': '气缸数',
  'Total No of Samples': '样品总数',
  'Date Sampled': '取样日期',
  'Date Landed': '登陆日期',
  'Date Reported': '报告日期',
  'Percent of MCR (PCT)': 'MCR 的百分比 (PCT)',
  'Max Speed rpm': '最大转速 rpm',
  'Max Output KW': '最大输出功率',
  'Max Output': '最大输出',
  'Actual Speed rpm': '实际转速',
  'Actual Output KW': '实际输出功率',
  Asset: '资产',
  'Asset Part': '资产部分',
  Fluid: '体液',
  AssetID: '资产ID',
  'Asset Mfg': '资产制造',
  'Asset Model': '资产模型',
  'Asset Serial No': '资产序列号',
  'Asset Worksite': '资产工地',
  'Asset Part Mfg': '资产零件制造',
  'Asset Part Model': '资产部分模型',
  'Asset Part Serial No': '资产部件序列号',
  'Asset Part Type': '资产部分类型',
  Manufacturer: '制造商',
  Grade: '年级',
  Parameter: '范围',
  Spec: '规格',
  Result: '结果',
  'Result Unit': '结果单位',
  'Reported Date': '报告日期',
  'Sampled Date': '采样日期',
  'Batch/Barcode No': '批号/条码号',
  Type: '类型',
  Company: '公司',
  Address: '地址',
  Phone: '电话',
  Email: '电子邮件',
  Reports: '报告',
  Quantity: '数量',
  'Sample Sequence': '样本序列',
  'Prep Code': '准备代码',
  'Analytical Package or Elements Requested': '要求提供分析包或元素',
  Controls: '控件',
  'File Name': '文件名',
  'Inbound ID': '入境编号',
  'Processed On': '处理时间',
  'Ext System Code': '分机系统代码',
  'Created On From': '创建于',
  'Created On To': '创建于',
  'Spec Met/ Not Met': '符合/不符合规范',
  Nav_Menu_Home: '家',
  Nav_Menu_Dashboard_Mineral: '仪表板矿物',
  Nav_Menu_Dashboard_Food: '仪表板食品',
  Nav_Menu_Dashboard_BVAQ: '仪表板 BVAQ',
  Nav_Menu_Knowledge_Center: '知识中心',
  Nav_Menu_Trending: '热门',
  Nav_Menu_Jobs_And_Samples: '工作与样品',
  Nav_Menu_Jobs: '职位',
  Nav_Menu_Jobs_Details: '职位详情',
  Nav_Menu_Samples: '样品',
  Nav_Menu_Reports_And_Invoices: '报告和发票',
  Nav_Menu_Invoices: '发票',
  Nav_Menu_Reports: '报告',
  Nav_Menu_Administrator: '行政人员',
  Nav_Menu_Documents: '文件',
  Nav_Menu_Feedback: '回馈',
  Nav_Menu_News: '消息',
  Nav_Menu_Roles: '角色',
  Nav_Menu_Users: '用户',
  Nav_Menu_NotificationTemplate: '通知模板',
  Nav_Menu_Alerts: '警报',
  Nav_Client_Quotes: '客户报价',
  Nav_Equipments: '设备',
  Nav_Actions: '动作',
  Nav_Menu_ClientQuotes: '客户报价',
  Nav_Menu_Equipments: '设备',
  Nav_Menu_Submission: '提交',
  Nav_Menu_Data_Schema: '数据模式',
  Nav_Menu_Subscriptions: '订阅',
  Nav_Menu_SampleSubmission: '意见书',
  Nav_Sample_Submission: '意见书',
  Nav_Menu_Target: '目标',
  Nav_Menu_API: '应用程序接口',
  Nav_Menu_TranslationSets: '翻译集',
  Nav_Menu_MyActions: '我的行动',
  Nav_Menu_ActionCenter: '活动中心',
  Nav_Menu_APILog: 'API日志',
  Nav_Menu_AssetManagement: '资产管理',
  Nav_Menu_Equipment: '设备',
  Nav_Menu_EquipmentBrowse: '浏览设备',
  NewUser: '新用户',
  UserList: '用户列表',
  Search: '搜索',
  All: '全部',
  News: '消息',
  Header_Home: '家',
  Header_SampleSearch: '样品',
  Header_ActionsSearch: '动作',
  Header_JobSearch: '职位',
  Header_SampleDetails: '样品详情',
  Header_SampleInfo: '样品信息',
  Header_Results: '结果',
  Header_JobInfo: '招聘信息',
  Header_InPrep: '准备中',
  Header_InTransit: '在途中',
  Header_InAnalysis: '分析中',
  Header_JobAge: '工作年龄',
  Header_SampleAge: '样本年龄',
  Header_In: '在',
  Header_Out: '出去',
  Header_RecentNews: '最近的新闻',
  Header_JobProgress: '工作进度',
  Header_SampleProgress: '示例进度',
  Header_RoleSearch: '角色',
  Header_NewsSearch: '消息',
  Header_DocumentSearch: '文件',
  Header_Document: '文档',
  Header_AddDocument: '添加新文档',
  Header_EditDocument: '编辑文档',
  Header_News: '消息',
  Header_General: '一般的',
  Header_Access: '使用权',
  Header_KnowledgeCenter: '知识中心',
  Header_Documents: '文件',
  Header_AccountSetting: '帐号设定',
  Header_Profile: '轮廓',
  Header_ReportSearch: '报告',
  Header_Analysis: '分析',
  Header_Trending: '热门',
  Header_MyActions: '我的行动',
  Header_InvoiceSearch: '发票',
  Header_NotificationSetting: '通知模板',
  Header_Triggers: '触发器',
  Header_Templates: '模板',
  Header_Template: '模板',
  Header_Roles: '角色',
  Header_Permissions: '权限',
  Header_NotificationsFor: '通知',
  Header_InvoiceInfo: '发票信息',
  Header_Conditions: '状况',
  Header_User: '用户',
  Header_UserSearch: '用户',
  Header_UserEdit: '用户编辑',
  Header_UserCreate: '用户创建',
  Header_AttachedDocuments: '附加文件',
  Header_InvoiceDetail: '发票明细',
  Header_FeedbackSearch: '回馈',
  Header_JobDetail: '职位详情',
  Header_SamplePreparationAndAnalyticalProcedures: '样品制备和分析程序',
  Header_JobQuantityDataCalculation: '数量数据计算',
  Header_JobTimeLogCalculation: '时间日志计算',
  Header_JobStorageInformation: '作业存储信息',
  Header_Deliverables: '可交付成果',
  Header_Alerts: '警报',
  Header_Attachments: '附件',
  Header_Result_History: '结果历史',
  Header_Status: '状态',
  Header_Actions: '动作',
  Header_ClientQuotes: '客户报价',
  Header_Equipments: '设备',
  Header_AddClientQuotes: '新客户报价',
  Header_EditClientQuotes: '编辑客户报价',
  Header_DataSchema: '数据模式',
  Header_JobMessages: '求职讯息',
  Header_Subscriptions: '订阅',
  Header_EditSchema: '编辑架构',
  Header_AddSubmission: '新样品提交',
  Header_EditSubmission: '编辑样本提交',
  Header_Submission: '意见书',
  Header_Targets: '目标',
  Header_EditTarget: '编辑目标',
  Header_TranslationSets: '翻译集',
  Header_EditTranslationSets: '编辑翻译集',
  Header_EditSubscription: '传出订阅',
  Header_Action_Column_New: '去做',
  Header_Action_Column_InProgress: '进行中',
  Header_Action_Column_Completed: '完全的',
  Header_APILog: 'API日志',
  Header_Sample_Results_History: '示例结果历史记录',
  Header_Lubrication: '润滑',
  Header_CylinderOil: '汽缸油',
  Header_Assets: '资产',
  Header_Add_New_Widget: '添加新小部件',
  Header_AssetManagement: '资产管理',
  Header_VesselInformation: '船舶信息',
  Header_ClientDetails: '客户详情',
  Header_ClientSiteDetails: '客户站点详细信息',
  Header_Equipment_Details: '设备详情',
  Header_Recommendation: '推荐',
  Header_Graphs: '图表',
  Header_NoAssetName: '无资产名称',
  Header_SubmissionDetails: '提交详情',
  Header_SubmissionContacts: '提交联系人',
  Header_SampleList: '样品清单',
  Header_InvoiceDetailList: '发票明细表',
  Header_ComponentList: '组件清单',
  Header_AddTimeLogCalculation: '添加时间日志计算',
  Header_EditTimeLogCalculation: '编辑时间日志计算',
  Header_TimeLogCalculationInfo: '时间日志计算信息',
  Breadcrumb_TimeLogCalculation: '时间日志计算',
  Breadcrumb_Home: '家',
  Breadcrumb_Users: '用户',
  Breadcrumb_ClientQuotes: '客户报价',
  Breadcrumb_Submissions: '意见书',
  Breadcrumb_DataSchemas: '数据模式',
  Breadcrumb_TranslationSets: '翻译集',
  Breadcrumb_Targets: '目标',
  Breadcrumb_Subscriptions: '订阅',
  Breadcrumb_AssetManagement: '资产管理',
  Breadcrumb_Document: '文档',
  Breadcrumb_Documents: '文件',
  Breadcrumb_Invoices: '发票',
  Breadcrumb_InvoiceDetails: '发票明细',
  Label_JobStatus: '工作现状',
  Label_No_Widget: '没有小部件',
  Label_SampleName: '样本名称',
  Label_Analysis: '分析',
  Label_SampleDescription: '示例说明',
  Label_SampleSource: '样品来源',
  Label_SamplingDateFrom: '采样日期从',
  Label_SamplingDateTo: '采样日期至',
  Label_DateCreatedFrom: '创建日期',
  Label_DateCreatedTo: '创建日期至',
  Label_SampleStatus: '样品状态',
  Label_Location: '地点',
  Label_Client: '客户',
  Label_Clients: '客户',
  Label_Projects: '项目',
  Label_Project: '项目',
  Label_ProjectSites: '项目/站点',
  Label_Name: '姓名',
  Label_TargetDuration: '描述',
  Label_TimestampEvent1: '时间戳事件 1',
  Label_TimestampEvent2: '时间戳事件 2',
  Label_Job: '工作',
  Label_Sample: '样本',
  Label_AvgDays: '平均天数',
  Label_Last3Months: '最近 3 个月',
  Label_Jobs: '职位',
  Label_Samples: '样品',
  Label_TurnaroundTime: '周转时间',
  Label_Received: '已收到',
  Label_PrepStarted: '准备开始',
  Label_InAnalysis: '分析中',
  Label_Due: '到期的',
  Label_DueDate: '截止日期',
  Label_AssignedTo: '分配给',
  Label_ActionTitle: '动作标题',
  Label_ActionType: '动作类型',
  Label_ActionNumber: '行动号',
  Label_ActionsFor: '行动为',
  Label_ActionStatus: '动作状态',
  Label_Reported: '举报了',
  Label_Invoiced: '开具发票',
  Label_RoleName: '角色名称',
  Label_Active: '积极的',
  Label_InActive: '不活动',
  Label_Display: '展示',
  Label_Title: '标题',
  Label_Zone: '区',
  Label_DocumentType: '文件类型',
  Label_Roles: '角色',
  Label_Locations: '地点',
  Label_Division: '分配',
  Label_Variant: '变体',
  Label_TestItems: '测试项目',
  Label_AssignedLocations: '分配的位置',
  Label_AssignedDivisions: '分配的部门',
  Label_SourceSystems: '源系统',
  Label_Description: '描述',
  Label_Summary: '概括',
  Label_Details: '细节',
  Label_User: '用户',
  Label_ImpersonateUser: '冒充用户',
  Label_Sort: '种类',
  Label_Direction: '方向',
  Label_FromAToZ: '从A到Z',
  Label_FromZToA: '从 Z 到 A',
  Label_ReportName: '报告名称',
  Label_JobNumber: '工作编号',
  Label_DateGeneratedFrom: '生成日期',
  Label_DateGeneratedTo: '生成日期至',
  Label_JobName: '职位名称',
  Label_ReportType: '报告类型',
  Label_Template: '模板',
  Label_Subject: '学科',
  Label_Body: '身体',
  Label_NotificationType: '通知类型',
  Label_EmailPlaceHolder: '电子邮件占位符',
  Label_Message: '信息',
  Label_Permissions: '权限',
  Label_Comments: '注释',
  Label_CreatedBy: '由...制作',
  Label_CreatedOn: '创建于',
  Label_Rating: '评分',
  Label_Feedback: '回馈',
  Label_NewPassword: '新密码',
  Label_ConfirmNewPassword: '确认新密码',
  Label_CustomerAlert: '客户提醒',
  Label_Sites: '站点',
  Label_From: '从',
  Label_To: '到',
  Label_FirstName: '名',
  Label_LastName: '姓',
  Label_Email: '电子邮件',
  Label_DateFormat: '日期格式',
  Label_DateTimeFormat: '日期时间格式',
  Label_Timezone: '时区',
  Label_Role: '角色',
  Label_SearchUser: '搜索用户',
  Label_Invoice: '发票',
  Label_InvoiceNumber: '发票编号',
  Label_InvoiceType: '类型',
  Label_InvoiceDateFrom: '发票日期',
  Label_InvoiceDateTo: '发票日期至',
  Label_CC: 'CC',
  Label_BCC: '密件抄送',
  Label_DateReceivedFrom: '收到日期',
  Label_DateReceivedTo: '收到日期至',
  Label_DateCompletedFrom: '完成日期自',
  Label_DateCompletedTo: '完成日期至',
  Label_RecentJobs: '最近的工作',
  Label_LinkedJobs: '领英职位',
  Label_RecentSamples: '最近的样品',
  Label_Stretch: '拉紧',
  Label_Compact: '袖珍的',
  Label_ForgotPassword: '忘记密码',
  Label_SampleByStatus: '按状态抽样',
  Label_Last30days: '最近 30 天',
  Label_YTD: '年初至今',
  Label_SpecResult: '规格结果',
  Label_QuoteNumber: '报价编号',
  Label_AttachedFile: '附件',
  Label_Supplier: '供应商',
  Label_QuotationInfo: '报价信息',
  Label_Attachments: '附件',
  Label_Laboratory: '实验室',
  Label_ContractNumber: '合同号码',
  Label_StartDate: '开始日期',
  Label_EndDate: '结束日期',
  Label_DateFrom: '日期从',
  Label_DateTo: '日期至',
  Label_SchemaName: '架构名称',
  Label_SchemaType: '模式类型',
  Label_SourceSystem: '源系统',
  Label_DataSourceType: '数据源类型',
  Label_ContentType: '内容类型',
  Label_SampleLocation: '样品位置',
  Label_SubscriptionName: '订阅名称',
  Label_Target: '目标',
  Label_TargetDetail: '目标详情',
  Label_EventTrigger: '事件触发器',
  Label_General: '一般的',
  Label_TranslationSet: '翻译集',
  Label_ClientOrderNumber: '客户订单号',
  Label_ClientQuotation: '客户报价',
  Label_DispatchDate: '发货日期',
  Label_TransportCompany: '运输公司',
  Label_ConsignmentNoteNumber: '托运单号',
  Label_DispatchedIn: '发货时间',
  Label_ProjectNumber: '项目编号',
  Label_SDINumber: 'SDI编号',
  Label_HazardsMaterial: '危害物质',
  Label_HazardOther: '危害其他',
  Label_TurnAroundTime: '周转时间',
  Label_SamplePreperation: '样品制备',
  Label_SampleMatrix: '样本矩阵',
  Label_CountryOfOrigin: '原产地',
  Label_Priority: '优先事项',
  Label_Trending: '热门',
  Label_Filters: '过滤器',
  Label_ChartTypes: '图表类型',
  Label_Custom: '风俗',
  Label_TargetName: '目标名称',
  Label_MessageMappping: '消息映射',
  Label_ItemSetting: '项目设置',
  Label_Output: '输出',
  Label_Markers: '标记',
  Label_NewRow: '新行',
  Label_Label: '标签',
  Label_Value: '价值',
  Label_DataType: '数据类型',
  Label_Type: '类型',
  Label_Operator: '操作员',
  Label_Required: '必需的',
  Label_Translatable: '可译',
  Label_TriggerTemplate: '触发器模板',
  Label_MatchingCondition: '匹配条件',
  Label_TranslationKey: '翻译键',
  Label_DataSchema: '数据模式',
  Label_TranslationSetName: '翻译集名称',
  Label_Field: '场地',
  Label_Table: '桌子',
  Label_Condition: '健康）状况',
  Label_TemplateCondition: '模板条件',
  info_PermissionName_and_PermissionCode_CanNotBeEmpty: '权限名称和权限代码不能为空',
  Label_Method: '方法',
  Label_Path: '小路',
  Label_ProcessStatus: '进程状态',
  Label_Error: '错误',
  Label_DispatchStatus: '发货状态',
  Label_Response: '回复',
  Label_Host: '主持人',
  Label_APIPath: 'API路径',
  Label_Encoding: '编码',
  Label_AuthenticationSchema: '身份验证架构',
  Label_HttpMethod: 'HTTP方法',
  Label_BearerToken: '不记名令牌',
  Label_WebAPI: 'WebAPI',
  Label_MessageType: '消息类型',
  Label_Headers: '标头',
  Label_ShowHideTree: '显示/隐藏树',
  Label_SerialNo: '序列号',
  Label_EquipmentName: '设备名称',
  Label_EquipmentNo: '设备编号',
  Label_Site: '地点',
  Label_Model: '模型',
  Label_Manufacturer: '制造商',
  Label_ClientInformation: '客户信息',
  Label_QuickFilter: '快速筛选',
  Label_DateCreated: '创建日期',
  Label_DateReceived: '接收日期',
  Label_Default: '默认',
  Label_RowsPerPage: '每页行数：',
  Label_ShowHideFilers: '显示/隐藏过滤器',
  Label_NewActionFor: '新行动',
  Label_ShowingNEquipments: '显示{{n}}个设备',
  Label_ShowHideOutput: '显示/隐藏输出',
  Label_ReportedOn: '报道于',
  Label_ReportReprint: '报告转载',
  Label_Approved: '得到正式认可的',
  Label_OrderNumber: '订单号',
  Label_Permission_Name_is_required: '权限名称为必填项',
  Label_Permission_Code_is_required: '需要权限码',
  Label_Title_is_required: '标题为必填项',
  Label_Max_length_n: '最大长度为 {{n}}',
  Label_Document_Type_is_required: '文件类型为必填项',
  Label_Description_is_required: '描述为必填项',
  Label_File_is_required: '需要文件',
  Label_Load_document_failed: '加载文档失败',
  Label_SubmissionID: '提交ID',
  Label_CreatedOnTo: '创建于',
  Label_CreatedOnFrom: '创建于自',

  Button_Save: '救球',
  Button_Save_As_Template: '另存为模板',
  Button_SaveFilter: '保存过滤器',
  Button_SavedFilter: '保存的过滤器',
  Button_Submit: '提交',
  Button_Update: '更新',
  Button_Test: '测试',
  Btn_ShowFilter: '显示过滤器',
  Btn_HideFilter: '隐藏过滤器',
  Button_Back: '后退',
  Button_Delete: '删除',
  Btn_Apply: '申请',
  Btn_Reset: '重启',
  Btn_ClearFilters: '清除过滤器',
  Btn_ManageDisplay: '管理显示',
  Btn_StopImpersonating: '停止冒充',
  Btn_Logout: '登出',
  Btn_Add: '添加',
  Btn_Cancel: '取消',
  Btn_Yes: '是的',
  Btn_No: '不',
  Btn_Upload: '上传',
  Btn_NewDocument: '文档',
  Btn_AddNews: '消息',
  Btn_Submit: '提交',
  Btn_Settings: '设置',
  Btn_Profile: '轮廓',
  Btn_ManageFilters: '管理过滤器',
  Btn_Clear: '清除',
  Btn_Save: '救球',
  Btn_PlaceHolders: '占位符',
  Btn_AddRole: '角色',
  Btn_AddActions: '新动作',
  Btn_EditNotifications: '通知',
  Btn_Export: '出口',
  Btn_Close: '关',
  Btn_Preview: '预习',
  Btn_ChangeMyPassword: '修改我的密码',
  Btn_ManageDate: '管理日期',
  Btn_ViewUser: '查看用户',
  Btn_Next: '下一个',
  Btn_Previous: '以前的',
  Btn_Complete: '完全的',
  Btn_LinkMore: '... 更多的',
  Btn_Refresh: '刷新',
  Btn_SaveOrder: '保存订单',
  Btn_Create_Request: '创建请求',
  Btn_Beautify: '美化',
  Btn_Minify: '缩小',
  Btn_AddNew: '添新',
  Btn_Submission: '提交',
  Btn_Start: '开始',
  Btn_ReOpen: '重开',
  Btn_Comment: '评论',
  Btn_ShowMoreComments: '更多的',
  Btn_StartAction: '开始行动',
  Btn_NewAction: '新动作',
  Btn_Load5MoreLastCompletedActions: '载入 5 个以上最后完成的动作',
  Btn_HideOutput: '隐藏输出',
  Btn_ShowOutput: '显示输出',
  Column_Action: '行动',
  column_DocumentTypeName: '文件类型',
  Column_Title: '标题',
  Column_JobNumber: '工作编号',
  Column_Filename: '文件名',
  Column_GeneratedOn: '生成于',
  Column_Date: '日期',
  Column_Headline: '标题',
  Column_Select: '选择',
  Column_FirstName: '名',
  Column_LastName: '姓',
  Column_EmailAddress: '电子邮件地址',
  Column_UpdatedOn: '更新时间',
  Column_Category: '类别',
  Column_Severity: '严重性',
  Column_Sample_Date: '采样日期',
  Column_Component_Age: '组件年龄',
  Column_Lube_Age: '润滑油年龄',
  Column_Filter_Changed: '过滤器已更改',
  Column_Product: '产品',
  Column_Website: '网站',
  Column_Sample_Number: '样品编号',
  Column_IR: '红外线',
  Column_CR: '铬',
  Column_NI: '你',
  Column_AL: '阿尔',
  Column_LD: '低密度脂蛋白',
  Column_CO: '一氧化碳',
  Column_TI: 'TI',
  Column_SI1: '国际单位制',
  Column_TT: 'TT',
  Column_VA: '弗吉尼亚州',
  Column_SI2: '国际单位制',
  Column_ModelID: '模型',
  Column_AssetNumber: '资产编号',
  Column_Component: '零件',
  Column_Manufacturer: '制造商',
  Column_Model: '模型',
  Column_Serial: '连续剧',
  Column_TimeLogCalcDisplayName: '姓名',
  Column_TimeLogEventName1: '开始',
  Column_TimeLogEventName2: '结尾',
  Column_Duration: '结果（持续时间',
  Column_AssetNo: '资产编号',
  Column_SerialNo: '序列号',
  Column_CreatedOn: '创建于',
  Column_Client: '客户',
  Column_ContractNumber: '合同号码',
  Column_JobName: '职位名称',
  Column_JobStatusName: '工作状态名称',
  Column_JobDescription: '职位描述',
  Column_ClientReference: '客户参考',
  Column_JobStartDate: '工作开始日期',
  Column_JobCoordinator: '工作协调员',
  Column_JobDateReceived: '收到工作日期',
  Column_InvoiceNumber: '发票编号',
  Column_Type: '类型',
  Column_Amount: '数量',
  Column_InvoiceDate: '发票日期',
  Column_PaymentDueDate: '付款截止日期',
  'Column_Sample Details': '样品详情',
  Column_LineItemDescription: '描述',
  Column_Unit: '单元',
  Column_Currency: '货币',
  Column_Quantity: '数量',
  'Column_Unit Price': '单价',
  Column_VAT: '增值税',
  Column_TotalWithTax: '总计（含增值税）',
  Column_TotalWithoutTax: '总计（不含增值税）',
  Column_JobCompletionDate: '工作完成日期',
  Column_StatusName: '状态名称',
  Column_DateReceived: '收到日期',
  Column_AnalysticalStartDate: '分析开始日期',
  Column_AnalyticalStartDate: '分析开始日期',
  Column_FirstDateFinished: '第一次约会结束',
  Column_SampleCount: '样本数',
  Column_FirstSampleNumber: '第一个样品编号',
  Column_LastSampleNumber: '最后样品编号',
  Column_SampleTypeName: '样本类型名称',
  Column_ClientNames: '客户名称',
  Column_ClientOrderNo: '客户订单号',
  Column_HasCOA: '有COA',
  Column_SampleName: '样本名称',
  Column_Selection: '选择',
  Column_ClientSampleNo: '客户样品编号',
  Column_SampleSequence: '样本序列',
  Column_LocationName: '地点名称',
  Column_ReceivedOn: '收到时间',
  Column_SamplingDate: '采样日期',
  Column_BatchLotRef: '批次参考',
  Column_AnalysisStartDate: '分析开始日期',
  Column_AnalysisCompleteDate: '分析完成日期',
  Column_ClientSampleRef: '客户样本参考',
  Column_FilterAge: '过滤年龄',
  Column_TimeSinceFilterChange: '自过滤器更换以来的时间',
  Column_OwnerName: '业主姓名',
  Column_ActionTypeName: '操作类型名称',
  Column_DueDate: '截止日期',
  Column_BelongsTo: '属于',
  Column_ObjectName: '对象名称',
  Column_CreatedByName: '按名称创建',
  Column_AcceptedOn: '接受日期',
  Column_AcceptedByName: '按名称接受',
  Column_CompletedOn: '完成于',
  Column_CompletedByName: '按姓名填写',
  Column_ParameterName: '参数名称',
  Column_SpecName: '规格名称',
  Column_ResultValue: '结果值',
  Column_ResultUniName: '结果单位名称',
  Column_SiteName: '站点名称',
  Column_SampleDescription: '示例说明',
  Column_SubmissionID: '提交编号',
  Column_SubmittedBy: '由...所提交',
  Column_SubmittedOn: '提交于',
  Column_InboundID: '入境编号',
  Column_ProcessedOn: '处理时间',
  Column_ExtSystemCode: '分机系统代码',
  Column_Object: '目的',
  Column_Record: '记录',
  Column_Comment: '评论',
  Column_Rating: '评分',
  'Column_Created On': '创建于',
  'Column_Created By': '由...制作',
  Column_Username: '用户名',
  Column_ReportType: '报告类型',
  Column_Project: '项目',
  Column_Brand: '品牌',
  Column_Description: '描述',
  Column_TimeLogCalculationDescription: '描述',
  Column_Source: '资源',
  Column_Supplier: '供应商',
  Column_SampleLocation: '样品位置',
  Column_ProcedureCode: '程序代码',
  Column_Samples: '样品',
  Column_CodeDescription: '代码说明',
  Column_Result: '结果（持续时间',
  Label_Start: '开始',
  Label_End: '结尾',
  Column_Status: '状态',
  Column_Lab: '实验室',
  Column_ClientOrderNumber: '客户订单号',
  Column_AssetName: '资产名称',
  Column_ClientName: '客户名称',
  Column_ManufacturerName: '生产商名称',
  Column_SampledDate: '采样日期',
  Column_DocumentID: '文件编号',
  Column_CreatedBy: '由...制作',
  InEx: '进/出',
  Column_Calculation: '计算',
  Column_Dry_Weight: '干重（DMT）',
  Column_Moisture: '水分',
  Column_Wet_Weight: '湿重 (WMT)',
  Column_JobParcelID: '包裹',
  Column_VefFactor: 'VEF因子',
  Toast_Add_new_document_successfully: '添加新文档成功',
  Toast_Add_document_failed: '添加文档失败',
  Toast_Create_document_failed: '创建文档失败',
  Toast_UpdatedSuccessfully: '更新成功',
  Toast_UpdateFailed: '更新失败',
  Toast_CreatedSuccessfully: '创建成功',
  Toast_SelectedSuccessfully: '入选成功',
  Toast_SavedSuccessfully: '保存成功！',
  Toast_ColumnDisplayUpdatedSuccessfully: '列显示更新成功！',
  Toast_ColumnDisplayUpdateFailed: '列显示更新失败！',
  Toast_ColumnsCannotBeRertieved: '无法检索列！',
  Toast_UnableToSaveFilter: '无法保存过滤器预设！',
  Toast_FilterDisplayUpdatedSuccessfully: '过滤器显示更新成功！',
  Toast_FilterDisplayUpdateFailed: '过滤器显示更新失败！',
  Toast_ConfiguredFieldsCannotBeRertieved: '配置的字段无法取回！',
  Toast_ActionStatusUpdatedSuccessfully: '动作状态更新成功！',
  Toast_FilesUploadedSuccessfully: '文件上传成功',
  Toast_FileRemovedSuccessfully: '文件删除成功',
  Toast_FileDownloadedSuccessfully: '文件下载成功！',
  Error_Message_NoDataFound: '没有找到数据',
  Error_LoadingError: '加载错误',
  Error_Message_NoColumnsFound: '未找到任何列！',
  Error_NoItemsFound: '未找到任何项目！',
  Error_NoPresetFound: '没有找到预设！',
  Info_RecoveryPasswordEmailHasBeenSent: '恢复密码电子邮件已发送',
  Confirm_Message_DeleteTemplate: '您确定要删除此模板吗？',
  Confirm_Message_Delete: '您确定要删除此记录吗？',
  Confirm_Title_ArchiveDocument: '归档文件：',
  Confirm_Message_ArchiveDocument: '您确定要存档此文档吗？',
  Confirm_Title_UnarchiveDocument: '解档文档：',
  Confirm_Message_UnarchiveDocument: '您确定要取消存档此文档吗？',
  Confirm_Title_ConfirmDelete: '确认删除',
  Info_Message_ExportSuccess: '导出数据成功',
  Error_ActionTypeRequired: '动作类型是必需的',
  Error_AssignedToRequired: '分配给是必需的',
  Error_TitleRequired: '标题是必需的',
  Error_DueDateRequired: '截止日期是必需的',
  Error_LaboratoryIsRequired: '需要实验室',
  Error_DispatchDateIsRequired: '需要发货日期',
  Error_TurnAroundTimeIsRequired: '需要周转时间',
  Error_HazardsMaterialIsRequired: '需要危险材料',
  Error_DispatchedInIsRequired: '派遣在是必需的',
  Error_Message_ExportSuccess: '导出数据出错',
  Info_Message_SaveSuccessfully: '保存成功',
  Error_Message_SaveError: '保存失败',
  Info_Message_DownloadSuccess: '下载成功',
  Error_Message_DownloadError: '下载失败',
  Info_Message_DeleteSuccess: '删除成功',
  Error_Message_DeleteError: '删除失败',
  Error_Message_ChangePasswordError: '更改密码失败',
  Info_Message_UpdateUserSuccess: '更新用户成功',
  Error_Message_UpdateUserError: '更新用户失败',
  Error_Message_LoadUserError: '加载用户失败',
  Error_ClientIsRequired: '客户端是必需的',
  Error_LocationIsRequired: '位置是必需的',
  Error_SourceSystemIsRequired: '需要源系统',
  Error_DataSourceTypeIsRequired: '数据源类型是必需的',
  Error_ContentTypeIsRequired: '内容类型是必需的',
  Info_Message_DownloadReportsSuccess: '报告下载成功！',
  Info_Message_DownloadInvoicesSuccess: '发票下载成功！',
  info_Permission_SaveSuccessfully: '权限保存成功',
  Error_Message_SomethingWentWrong: '出了些问题！',
  Error_TemplateNameIsRequired: '模板名称是必需的',
  Error_LoadingDataError: '加载数据错误',
  Created_Submission_Successfully: '创建提交成功',
  Error_TargetNameIsRequired: '目标名称是必需的',
  Error_DivisionIsRequired: '除法是必需的',
  Info_Message_MessageMappingModes: '消息映射模式',
  Error_TranslationSetNameIsRequired: '翻译集名称是必需的',
  Error_BelongsToIsRequired: '属于是必需的',
  Error_RecordIDIsRequired: 'RecordID 是必需的',
  Error_ObjectTypeIsRequired: '对象类型为必填项',
  Error_EventTriggerIsRequired: '需要事件触发器',
  Error_TemplateIsRequired: '模板是必需的',
  Error_TargetDetailIsRequired: '需要目标详细信息',
  Error_WebApiIsRequired: 'Web API 是必需的',
  Error_BearerTokenIsRequired: '不记名令牌是必需的',
  Error_PostIsRequired: '帖子是必需的',
  Error_HostNameIsRequired: '主机名是必需的',
  Error_APIPathIsRequired: '必须提供 API 路径',
  Error_EncodingIsRequired: '需要编码',
  Error_NameIsRequired: '姓名为必填项',
  Error_DateFromRequired: '日期为必填项',
  Error_DateToRequired: '截止日期为必填项',
  Error_TimeStampEvent1IsRequired: '时间戳事件 1 是必需的',
  Error_TimeStampEvent2IsRequired: '时间戳事件 2 是必需的',
  Error_TargetDurationIsRequired: '目標持續時間是必需的',
  Error_TargetDurationMustBeInDecimalForm: '目标持续时间必须以小数形式表示',

  Message_NoComments: '没有评论',
  PlaceHolder_Comments: '你的评论在这里...',
  Tab_General: '一般的',
  Tab_Profile: '轮廓',
  Tab_Notifications: '通知',
  Tooltip_Download: '下载',
  Tooltip_Archive: '档案',
  Tooltip_Unarchive: '取消归档',
  Tooltip_Default: '默认',
  Tooltip_DocumentSearch: '访问 BV |',
  Tooltip_KnowledgeCenter: '访问 BV |',
  Tooltip_AccountSetting: '访问 BV |',
  Tooltip_ReportSearch: '访问 BV |',
  Tooltip_NotificationSetting: '访问 BV |',
  Tooltip_Favorite: '最喜欢的',
  Tooltip_Unfavorite: '不喜欢',
  Tooltip_Export_To_CSV: '导出为 CSV',
  Tooltip_ClientQuotes: '访问 BV |',
  Tooltip_Equipments: '访问 BV |',
  Tooltip_AddClientQuotes: '新客户报价',
  Tooltip_EditClientQuotes: '访问 BV |',
  Tooltip_DataSchema: '访问 BV |',
  Tooltip_AddSubmission: '新提交',
  Tooltip_EditSubmission: '编辑提交',
  Tooltip_EditDataSchema: '访问 BV |',
  Tooltip_TranslationSets: 'Access BV |翻译集',
  Tooltip_Translations: '翻译',
  Tooltip_AddTranslationSets: '访问 BV |',
  Tooltip_EditTranslationSets: '访问 BV |',
  Tooltip_EditTarget: '编辑目标',
  Tooltip_Subscriptions: '访问 BV |',
  Tooltip_EditSubscription: '访问 BV |',
  Tooltip_Targets: '目标',
  Tooltip_CreateAction: '创建动作',
  Tooltip_APILog: '访问 BV |',
  Tooltip_Destination: '目的地',
  Tooltip_Setting: '环境',
  Tooltip_Authentication: '验证',
  Tooltip_General: '一般的',
  Tooltip_AssetManagement: '访问 BV |',
  Tooltip_EditLayout: '编辑布局',
  Tab_AllDocuments: '文档和说明手册',
  Tab_UserDocuments: '用户文件',
  Tab_LatestNews: '最新消息',
  Tab_NewsArchived: '新闻存档',
  Tab_Laboratory_Reports: '化验报告',
  Tab_Data: '数据',
  Tab_Invoices: '发票',
  Tab_Other: '其他',
  Tab_Documents: '文件',
  Tab_News: '消息',
  Tab_Design: '设计',
  Tab_Code: '代码',
  Tab_Decoration: '装饰',
  Tab_Conditions: '状况',
  Tab_Translation: '翻译',
  Title_NewTranslationSets: '新翻译集',
  Placeholder_Selectanoption: '选择一个选项',
  Text_AddClientQuotes: '新客户报价',
  Text_EditClientQuotes: '编辑客户报价',
  Text_Trigger: '扳机',
  Text_EditDataSchemas: '编辑数据模式',
  Text_AddSubmissions: '添加提交',
  Text_EditSubmissions: '编辑提交',
  Text_AddTranslationSets: '添加翻译集',
  Text_EditTranslationSets: '编辑翻译集',
  Text_EditTarget: '编辑目标',
  Text_EditSubscriptions: '编辑订阅',
  Axis_TurnaroundTime: '周转时间（天）',
  Label_Import_CSV: '导入 CSV',
  Label_belongsTo: '属于',
  Label_dateCreatedFrom: '创建日期',
  Label_dateCreatedTo: '创建日期至',
  Label_View_Details: '查看详细',
  Label_View_Feedback: '回馈',
  Label_reportsFor: '报告',
  Label_ReportsFor: '报告',
  Label_Add_Submission_Contacts: '提交联系人',
  Label_Add_Sample_List: '样品清单',
  Label_Add_Storage: '贮存',
  Label_Template_Name: '模板名称',
  Label_BelongsTo: '属于',
  Label_RecordID: '记录编号',
  Label_Add: '添加',
  Label_Save: '救球',
  Label_Inbound: '入境',
  Label_Outbound: '出站',
  Label_No_Records_Found: '没有找到记录！',
  Label_No_Invoice_Found: '未找到发票',
  Dialog_Title_FeedbackHeader: '意见反馈',
  Dialog_Title_NewActionForJob: '作业 {{name}} 的新操作',
  Dialog_Title_NewActionForSample: '示例 {{name}} 的新操作',
  Dialog_Title_NewActionForInvoice: '发票 {{name}} 的新操作',
  Dialog_Title_NewActionForReport: '报告 {{name}} 的新操作',
  Tab_Title_AppName: 'CTD AccessBV',
  Title_NewSubscription: '新订阅',
  Title_NewTarget: '新目标',
  Title_NewHeader: '新标题',
  Title_Showing_X_Records: '显示 {{length}} 条记录',
  Title_General: '一般的',
  Title_Access: '使用权',
  Title_AddTimeLogCalculation: '添加时间日志计算',
  Title_EditTimeLogCalculation: '编辑时间日志计算',
  Status_New: '新的',
  Status_Created: '已创建',
  Status_ReadyForAnalysis: '准备分析',
  Status_AnalysisStarted: '分析开始',
  Status_QCChecked: '质量控制检查',
  Status_JobFinished: '工作完成',
  Status_InAnalysis: '分析中',
  Status_InPrep: '准备中',
  Status_InProgress: '进行中',
  Status_Scheduled: '已预定',
  Status_Completed: '完全的',
  Status_Sampled: '取样',
  Status_Started: '开始',
  'Status_Not Sampled': '未采样',
  Status_Complete: '完全的',
  Recent_News: '最近的新闻',
  In_Prep_Count: '准备中',
  In_Transit_Count: '在途中',
  In_Analysis_Count: '分析中',
  Job_Age_Count: '工作年龄',
  In_Stats: '在',
  Out_Stats: '出去',
  Site_Map: '站点',
  Turnaround_Time: '周转时间',
  Recent_Jobs: '最近的工作',
  Recent_News_Description: '近期新闻说明',
  Site_Map_Description: '网站地图说明',
  In_Prep_Count_Description: '准备说明',
  In_Transit_Count_Description: '运输中描述',
  In_Analysis_Count_Description: '分析中描述',
  Job_Age_Count_Description: '工作年龄描述',
  In_Stats_Description: 'IN 统计说明',
  Out_Stats_Description: 'OUT 统计说明',
  Recent_Jobs_Description: '最近的职位描述',
  Turnaround_Time_Description: '这将显示工作周转时间',
  DocumentTypeID: '文档类型 ID',
  CreatedOn: '创建于',
  CreatedBy: '由...制作',
  document: '文档',
  Active: '积极的',
  No_items_found: '未找到任何项目',
  LastUpdatedOn: '最后更新于',
  LastUpdatedBy: '最后更新者',
  new: '新的',
  feedback: '回馈',
  No_Feedback_Found: '未找到反馈',
  Note_displayed_and_downloaded: '可以在知识中心部分显示和下载文件。',
  File: '文件',
  browse: '浏览',
  thorough_your_machine: '彻底检查你的机器',
  Label_Select_File: '选择文件',
  Label_Select_Files: '选择文件',
  Label_Drop_File: '拖放文件',
  Label_Drop_Files: '拖放文件',
  InvoiceDetails: '发票明细'
};
export default cnTranslation;
